<template>
  <!-- begin:: Footer -->
  <div class="footer bg-white py-2 d-flex flex-lg-column" id="kt_footer">
    <div
      class="d-flex align-items-center justify-content-between"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="text-dark">
        <img v-bind:src="footerLogo" alt="Powered by KAVA CRM" height="50px" />
      </div>
      <div class="nav nav-dark">
        <span class="text-muted font-weight-bold mr-2">
          &nbsp;&copy;&nbsp;{{ currentYear }}
        </span>
        <!--        <a-->
        <!--          href="https://www.kavacrm.com/"-->
        <!--          target="_blank"-->
        <!--          class="text-dark-75 text-hover-primary"-->
        <!--        >-->
        <!--          KAVA Business Solutions-->
        <!--        </a>-->
      </div>
    </div>
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooter",
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    },

    footerLogo() {
      return process.env.BASE_URL + "media/logos/KAVA_Powered.jpg";
    },

    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>
